import * as React from "react";
import { Box, Container, Heading, Paragraph } from "theme-ui";

interface ISection {
  id?: string;
  className?: string;
  sx?: object;
  title?: string;
  description?: string;
  children?: React.ReactNode;
  ref?: any;
  dark?: boolean;
}

export function Section({
  id,
  className,
  sx,
  title,
  description,
  children,
  ref,
  dark,
}: ISection) {
  return (
    <Box
      id={id}
      className={className}
      as='section'
      sx={{ paddingTop: 6, paddingBottom: 6, ...sx }}
      ref={ref}
    >
      <Container>
        {title && (
          <Heading
            variant='h2'
            sx={{
              textAlign: "center",
              maxWidth: 1000,
              margin: "0 auto",
              color: dark && "#fff",
            }}
          >
            {title}
          </Heading>
        )}
        {description && (
          <Paragraph
            sx={{
              textAlign: "center",
              maxWidth: 700,
              margin: "28px auto",
              color: dark && "#fff",
            }}
          >
            {description}
          </Paragraph>
        )}
        {children}
      </Container>
    </Box>
  );
}
