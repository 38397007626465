import * as React from "react";
import { Link } from "gatsby";
import { Layout } from "../components/layout/layout";
import { Button, Container, Flex, Heading, Paragraph } from "theme-ui";
import { Section } from "../components/layout/section";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
      <Section
        title='Page not found'
        description='😔 we couldn’t find what you were looking for.'
      >
        <Flex sx={{ justifyContent: "center" }}>
          <Button as={Link} to='/'>
            Go home.
          </Button>
        </Flex>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
